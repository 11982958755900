import { ButtonGroup, Center, Divider, Flex, Heading, HStack, Spacer } from "@chakra-ui/react"

type ToolbarProps = {
  buttons?: React.ReactNode
  others?: React.ReactNode
  title?: string
}

const Toolbar = ({ title, others, buttons }: ToolbarProps) => {
  return (
    <>
      <Flex marginBottom={4}>
        <Center marginEnd={4}>
          <Heading as="h1" size="lg" noOfLines={1}>
            {title}
          </Heading>
        </Center>

        <Spacer />

        <HStack spacing={6} mr={6}>
          {others}
        </HStack>

        <ButtonGroup variant='solid' spacing='6'>
          {buttons}
        </ButtonGroup>
      </Flex>

      <Divider mb={4} />
    </>
  )
}

export default Toolbar
