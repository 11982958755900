import {
  Box,
  Button,
  Center,
  Flex,
  Hide,
  Link,
  useClipboard,
  useToast,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { MailIcon, PhoneCallIcon, PinIcon } from "@iconicicons/react";
import { useEffect } from "react";
import ErrorCard from "../components/ErrorCard";
import LoadingCard from "../components/LoadingCard";
import Toolbar from "../components/Toolbar";
import { useGetPlaceQuery } from "../services/placeApi";
import Networks from "../components/Networks";

export default function ContactPage() {
  const { data: place, isLoading, isError } = useGetPlaceQuery();
  const { hasCopied: hasCopiedAddress, onCopy: onCopyAddress } = useClipboard(
    place?.address ?? ""
  );
  const toast = useToast();

  useEffect(() => {
    if (hasCopiedAddress) {
      toast({
        title: "Adresse copiée",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
  }, [hasCopiedAddress, toast]);

  return (
    <>
      <Toolbar title="Contact" />
      {isLoading && <LoadingCard />}
      {isError && <ErrorCard />}
      {!isLoading && !isError && (
        <Center>
          <Flex>
            <Box
              bg={"rubyred.700"}
              color="white"
              borderRadius="lg"
              // m={{ sm: 4, md: 16, lg: 10 }}
              p={{ sm: 5, md: 5, lg: 8 }}
            >
              <Box p={4}>
                <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                  <WrapItem>
                    <Box>
                      {/* <Text mt={{ sm: 3, md: 3, lg: 5 }} color="gray.500">
                      Fill up the form below to contact
                    </Text> */}
                      <Box py={{ base: 5, sm: 5, md: 8, lg: 8 }}>
                        <VStack pl={0} spacing={3} alignItems="flex-start">
                          {place?.phones.map((phone) => (
                            <Button
                              as={Link}
                              size="md"
                              height="48px"
                              variant="ghost"
                              justifyContent={"flex-start"}
                              href={`tel:${phone}`}
                              leftIcon={<PhoneCallIcon />}
                            >
                              {phone}
                            </Button>
                          ))}
                          {place?.mail && (
                            <Button
                              as={Link}
                              size="md"
                              height="48px"
                              variant="ghost"
                              href={`mailto:${place?.mail}`}
                              leftIcon={<MailIcon />}
                            >
                              {place?.mail}
                            </Button>
                          )}
                          <Button
                            size="md"
                            height="48px"
                            variant="ghost"
                            onClick={onCopyAddress}
                            leftIcon={<PinIcon />}
                          >
                            {place?.address}
                          </Button>
                        </VStack>
                      </Box>
                      <Networks/>
                    </Box>
                  </WrapItem>

                  <Hide below="md">
                    <WrapItem>
                      <Box bg="white" borderRadius="lg">
                        <iframe
                          title="cameo-map"
                          src={process.env.REACT_APP_GMAP}
                          width="600"
                          height="450"
                          style={{ border: 0, borderRadius: "24px" }}
                          allowFullScreen={false}
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </Box>
                    </WrapItem>
                  </Hide>
                </Wrap>
              </Box>
            </Box>
          </Flex>
        </Center>
      )}
    </>
  );
}
