import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Place } from './types'

export const placeApi = createApi({
    reducerPath: 'placeApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    endpoints: (builder) => ({
        getPlace: builder.query<Place, void>({
            query: () => `place?id=${process.env.REACT_APP_PLACE_ID}`,
        }),
    }),
})

export const { useGetPlaceQuery } = placeApi