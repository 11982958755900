import { Box, Divider, Flex, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { format } from 'date-fns'
import { fr } from 'date-fns/locale'
import { Link } from 'react-router-dom'
import { getCinecheckBundle } from '../services/cinecheck'
import { Movie, Schedule } from '../services/types'
import ScheduleTags from './ScheduleTags'

export type ScheduleCardProps = {
    schedule: Schedule
    movie: Movie
}

export default function ScheduleCard({schedule, movie}: ScheduleCardProps) {
  return (
      <Box bg={schedule.last ? "rubyred.700" : schedule.preview ? "bronze.700" : "smokyblack.800"} boxShadow={"xl"} padding={4} borderRadius={"md"} minW={"full"}>
          <Link to={`/movie/${movie.id}`}>
              <Flex gap={2}>
                  <Box flex={7}>
                      <Image objectFit="cover" borderRadius={"sm"} src={movie.thumbnail} />
                  </Box>
                  <Box flex={9}>
                      <VStack align={"flex-start"}>
                          <Heading fontSize={"xl"} fontFamily={"heading"} noOfLines={1}>
                              {movie.title}
                          </Heading>
                          <Divider />
                          <Text as={"b"}>{format(new Date(schedule.date), "HH:mm - eee dd/MM", { locale: fr })}</Text>
                          <HStack>{getCinecheckBundle(movie.restriction, movie.cinechecks, "24px")}</HStack>
                          <ScheduleTags schedule={schedule} />
                      </VStack>
                  </Box>
              </Flex>
          </Link>
      </Box>
  );
}
