import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Movie } from './types'

export const movieApi = createApi({
    reducerPath: 'movieApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    endpoints: (builder) => ({
        getMoviesByPlace: builder.query<Movie[], boolean | undefined>({
            query: (planned) => `movies?place=${process.env.REACT_APP_PLACE_ID}${planned !== undefined ? `&planned=${planned}` : ''}`,
        }),
        getMovieById: builder.query<Movie, string>({
            query: (movieId) => `movie?place=${process.env.REACT_APP_PLACE_ID}&id=${movieId}`,
        }),
    }),
})

export const { useGetMoviesByPlaceQuery, useGetMovieByIdQuery } = movieApi