import { Image } from "@chakra-ui/react";
import Year_6 from '../assets/cinechecks/leeftijd_6.svg'
import Year_9 from '../assets/cinechecks/leeftijd_9.svg'
import Year_12 from '../assets/cinechecks/leeftijd_12.svg'
import Year_14 from '../assets/cinechecks/leeftijd_14.svg'
import Year_16 from '../assets/cinechecks/leeftijd_16.svg'
import Year_18 from '../assets/cinechecks/leeftijd_18.svg'
import Year_all from '../assets/cinechecks/leeftijd_alle.svg'
import Violence from "../assets/cinechecks/geweld.svg"
import Drugs_alcohol from "../assets/cinechecks/drugs_alcohol.svg"
import Anxiety from "../assets/cinechecks/angst.svg"
import Sex from "../assets/cinechecks/seks.svg"
import Discrimination from "../assets/cinechecks/discriminatie.svg"
import Language from "../assets/cinechecks/grof_taalgebruik.svg"

export function getRestrictionCinecheck(restriction: number, size: string) {
    let cinecheck;

    switch (restriction) {
        case 1:
            cinecheck = Year_6
            break;
        case 2:
            cinecheck = Year_9
            break;
        case 3:
            cinecheck = Year_12
            break;
        case 4:
            cinecheck = Year_14
            break;
        case 5:
            cinecheck = Year_16
            break;
        case 6:
            cinecheck = Year_18
            break;
        default:
            cinecheck = Year_all
            break;
    }

    return <Image src={cinecheck} borderRadius={'full'} boxSize={size}/>
}

export function getCinecheck(key: string, size: string) {
    const cinechecks = {
        violence: Violence,
        drugs_alcohols: Drugs_alcohol,
        fear: Anxiety,
        sex: Sex,
        discrimination: Discrimination,
        language: Language
    }

    return <Image id={key} src={cinechecks[key as 'violence' | 'drugs_alcohols' | 'fear' | 'sex' | 'discrimination' | 'language']} borderRadius={'full'} boxSize={size}/>
}

export function getCinechecks(keys: string[], size: string) {
    return keys.map((key) => getCinecheck(key, size));
}

export function getCinecheckBundle(restriction: number, keys: string[], size: string) {
    return [getRestrictionCinecheck(restriction, size), ...getCinechecks(keys, size)];
}