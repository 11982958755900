import { extendTheme, type ThemeConfig } from "@chakra-ui/react"

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
}

export const theme = extendTheme({
    config: config,
    colors: {
        smokyblack: {
            50: '#f2f3f2',
            100: '#d9d9d9',
            200: '#c0c0c0',
            300: '#a7a7a7',
            400: '#8e8e8e',
            500: '#747474',
            600: '#5a5a5a',
            700: '#414141',
            800: '#262626',
            900: '#0c0d0c',
        },
        bronze: {
            50: '#fbf6e4',
            100: '#eee3c2',
            200: '#e0d19e',
            300: '#d4be78',
            400: '#c8ac54',
            500: '#ae923a',
            600: '#88722d',
            700: '#61511f',
            800: '#3a310f',
            900: '#151000',
        },
        gold: {
            50: '#fef6e0',
            100: '#f1e5bd',
            200: '#e7d396',
            300: '#dcc16f',
            400: '#d2b047',
            500: '#b8962d',
            600: '#8f7521',
            700: '#665316',
            800: '#3e3209',
            900: '#171100',
        },
        blackbean: {
            50: '#ffe6e6',
            100: '#f6bbbb',
            200: '#ef8f8f',
            300: '#e86363',
            400: '#e23836',
            500: '#c9221d',
            600: '#9c1916',
            700: '#70110f',
            800: '#440909',
            900: '#1b0101',
        },
        darkred: {
            50: '#ffe3e3',
            100: '#ffb4b4',
            200: '#fc8383',
            300: '#f95252',
            400: '#f82422',
            500: '#de0f09',
            600: '#ad0806',
            700: '#7c0303',
            800: '#4b0000',
            900: '#1f0000',
        },
        rubyred: {
            50: '#ffe6e6',
            100: '#f7bdbd',
            200: '#ed9293',
            300: '#e46767',
            400: '#db3d3d',
            500: '#c22524',
            600: '#981b1b',
            700: '#6d1212',
            800: '#43090a',
            900: '#1d0000',
        },
        bg: '#1F0F0E'
    },
    styles: {
        global: {
            body: {
                // bg:'url("./cameo_2.png") left top/ cover no-repeat;',
                bg: 'rubyred.800',
                color: 'white'
            }
        }
    }
})