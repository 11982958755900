import { VStack } from '@chakra-ui/react'
import EmptyCard from '../components/EmptyCard'
import ErrorCard from '../components/ErrorCard'
import LoadingCard from '../components/LoadingCard'
import MovieCard from '../components/MovieCard'
import Toolbar from '../components/Toolbar'
import { useGetMoviesByPlaceQuery } from '../services/moviesApi'

type MoviesPageProps = {
  soon: boolean;
};

export default function MoviesPage({ soon }: MoviesPageProps) {

  const {data: movies, isLoading, isError} = useGetMoviesByPlaceQuery(!soon)

  return (
    <>
      <Toolbar title={soon ? "Prochainement" : "A l'affiche"} />
      {isLoading && (
        <LoadingCard/>
      )}
      {isError && (
        <ErrorCard/>
      )}
      {!isLoading && !isError && (
        <VStack spacing={4} align={"start"} mb={8}>
          {movies!.map((m) => (
            <MovieCard key={m.id} movie={m} />
          ))}
        </VStack>
      )}
      {!isLoading && !isError && !movies?.length && (
        <EmptyCard/>
      )}
    </>
  );
}
