import { Box } from '@chakra-ui/react';
import React from 'react'
import ReactPlayer from 'react-player/lazy'

type MovieTrailerProps = {
    url?: string;
    width?: string;
};

export default function MovieTrailer({url, width}: MovieTrailerProps) {
  return !!url ? (
    <Box maxW={"100%"} width="xl" py={4}>
      <ReactPlayer url={url} style={{maxWidth: '100%'}} controls={true} />
    </Box>
  ) : (
    <></>
  );
}
