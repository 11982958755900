import {
  Heading,
  SimpleGrid,
  VStack,
} from "@chakra-ui/react";
import { format, isSameDay, parse } from "date-fns";
import { fr } from "date-fns/locale";
import { useMemo } from "react";
import { useGetMoviesByPlaceQuery } from "../services/moviesApi";
import { Movie } from "../services/types";
import EmptyCard from "./EmptyCard";
import ErrorCard from "./ErrorCard";
import LoadingCard from "./LoadingCard";
import ScheduleCard from "./ScheduleCard";


export default function SchedulesList() {
  const { data: movies, isLoading, isError, isSuccess } = useGetMoviesByPlaceQuery(true);
  const today = useMemo(() => new Date(), []);
  // eslint-disable-next-line
  const scheduledMovies = useMemo(() => movies !== undefined ? groupSchedulesByDate(movies) : [], [movies])

  function groupBy<T>(arr: T[], fn: (item: T) => any) {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
        const groupKey = fn(curr);
        const group = prev[groupKey] || [];
        group.push(curr);
        return { ...prev, [groupKey]: group };
    }, {});
}

  function groupSchedulesByDate(movies: Movie[]) {
    const scheduledMovies = movies.flatMap((m) => m.schedules.filter((s) => !s.secluded).map((s) => { return {movie: m, schedule: s} }))

    return groupBy(scheduledMovies, (item) => format(new Date(item.schedule.date), "yyyyMMdd"))
  }

  return (
      <>
          {isLoading && <LoadingCard />}
          {isError && <ErrorCard />}
          {isSuccess && Object.entries(scheduledMovies).length === 0 && <EmptyCard />}
          {isSuccess && Object.entries(scheduledMovies).length > 0 && (
              <SimpleGrid column={1} spacing={8}>
                  {Object.entries(scheduledMovies).map(([key, scheduledMovies]) => {
                    const day = parse(key, "yyyyMMdd", new Date())
                    const formattedDay = format(day, "eeee d MMMM yyyy", { locale: fr })

                      return (
                          <VStack key={`${key}-stack`} align={"flex-start"}>
                              <Heading key={`${key}-heading`} size={"md"} color={isSameDay(day, today) ? "gold" : "white" }>{formattedDay.charAt(0).toLocaleUpperCase() + formattedDay.slice(1)}</Heading>
                              {scheduledMovies.sort((a, b) => new Date(a.schedule.date).getTime() - new Date(b.schedule.date).getTime()).map((scheduledMovie) => (<ScheduleCard schedule={scheduledMovie.schedule} movie={scheduledMovie.movie} key={`schedule-${scheduledMovie.schedule.id}`} />))}
                          </VStack>
                      );
                  })}
              </SimpleGrid>
          )}
      </>
  );
}
