import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { InformationIcon } from '@iconicicons/react'
import React from 'react'

export default function EmptyCard() {
  return (
    <Box textAlign="center" backgroundColor={"blackbean.700"} boxShadow={"lg"} borderRadius={"lg"} py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={"blue.500"}
          rounded={"50px"}
          w={"55px"}
          h={"55px"}
          textAlign="center"
        >
          <InformationIcon color={"white"} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Il n'y a pas de films dans cette catégorie pour le moment.
      </Heading>
      <Text color={"gray.500"}>
        D'autres long métrages arriveront prochainement !
      </Text>
    </Box>
  );
}
