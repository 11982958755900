import { Stack, Flex, Heading, Button, Image, Text, useBreakpointValue, Tag, TagLabel, TagLeftIcon, Divider, Wrap } from '@chakra-ui/react';
import { ClockIcon, MapIcon, UsersIcon } from '@iconicicons/react';
import { minutesToHours } from 'date-fns';
import { Movie } from '../services/types'
import { Link as BaseLink } from "react-router-dom";
import MovieCinechecks from './movie/MovieCinechecks';


type MovieCardProps = {
  movie: Movie;
};

export default function MovieCard({movie}: MovieCardProps) {
  return (
      <Stack w={"full"} borderRadius="lg" height={{ sm: "476px", md: "sm" }} direction={{ base: "column", sm: "row" }} bg={"smokyblack.800"} boxShadow={"xl"} padding={4}>
          <Flex
              flex={useBreakpointValue({
                  base: 2,
                  sm: 2,
                  lg: 2,
                  xl: 1,
                  "2xl": 4,
              })}>
              <Image objectFit="cover" borderRadius={"xl"} boxSize="100%" src={movie.thumbnail} />
          </Flex>
          <Stack
              flex={useBreakpointValue({
                  base: 6,
                  sm: 6,
                  lg: 6,
                  xl: 6,
                  "2xl": 26,
              })}
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
              spacing={4}
              p={1}
              pt={2}>
              <Heading fontSize={"2xl"} fontFamily={"body"}>
                  <BaseLink key={`${movie.id}-link`} to={`/movie/${movie.id}`}>
                      {movie.title}
                  </BaseLink>
              </Heading>
              <Text textAlign={"start"} color={"gray.400"} noOfLines={3} px={3} w={"full"}>
                  {movie.description}
              </Text>
              <Wrap align={"flex-start"} mt={6}>
                  <Tag size={"md"} key={`${movie.id}-duration`} variant="subtle" colorScheme="gold">
                      <TagLeftIcon boxSize="24px" as={ClockIcon} />
                      <TagLabel>Durée: {!!movie?.duration ? `${minutesToHours(movie.duration)}:${String(movie.duration % 60).padStart(2, "0")}` : "-"}</TagLabel>
                  </Tag>
                  <Tag size={"md"} key={`${movie.id}-origin`} variant="subtle" colorScheme="gold">
                      <TagLeftIcon boxSize="24px" as={MapIcon} />
                      <TagLabel>Origine: {movie?.origin ?? "-"}</TagLabel>
                  </Tag>
                  <Tag size={"md"} key={`${movie.id}-actors`} variant="subtle" colorScheme="gold">
                      <TagLeftIcon boxSize="24px" as={UsersIcon} />
                      <TagLabel>Acteur(s): {movie?.actors ?? "-"}</TagLabel>
                  </Tag>
                  <Tag size={"md"} key={`${movie.id}-director`} variant="subtle" colorScheme="gold">
                      <TagLeftIcon boxSize="24px" as={UsersIcon} />
                      <TagLabel>Réalisateur(s): {movie?.director ?? "-"}</TagLabel>
                  </Tag>
              </Wrap>
              <Divider />
              <MovieCinechecks restriction={movie?.restriction!!} cinechecks={movie?.cinechecks ?? []} />
              <Button as={BaseLink} variant={"outline"} colorScheme={"blackbean"} to={`/movie/${movie.id}`}>
                  Voir plus
              </Button>
          </Stack>
      </Stack>
  );
}
