import { VStack } from "@chakra-ui/react";
import { isAfter } from "date-fns";
import { Movie } from "../../services/types";
import MovieSchedule from "./MovieSchedule";

type MovieSchedulesListProps = {
    movie: Movie;
};

export default function MovieSchedulesList({ movie }: MovieSchedulesListProps) {
    return !!movie.schedules.length ? (
        <VStack minW={"100%"} spacing={4} align={"start"}>
            {movie.schedules
                .filter((s) => isAfter(new Date(s.date), new Date()))
                .map((schedule) => (
                    <MovieSchedule schedule={schedule} />
                ))}
        </VStack>
    ) : (
        <></>
    );
}
