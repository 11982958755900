import { HStack, useConst } from "@chakra-ui/react";
import { isAfter } from "date-fns";
import { Schedule } from "../../services/types";
import MovieSchedule from "./MovieSchedule";

type MovieSchedulesProps = {
    schedules: Schedule[];
};

export default function MovieSchedules({ schedules }: MovieSchedulesProps) {
    const today = useConst(new Date());

    return (
        <HStack maxW={"full"} overflowX={"auto"} spacing={4} hidden={!schedules.length}>
            {schedules
                .filter((s) => isAfter(new Date(s.date), today))
                .filter((s) => s.secluded === false)
                .map((s) => (
                    <MovieSchedule schedule={s} />
                ))}
        </HStack>
    );
}
