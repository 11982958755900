import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'

export default function LoadingCard() {
  return (
    <Center mt={"36px"}>
      <Spinner size="xl" />
    </Center>
  );
}
