import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Hide,
  Image,
  Show,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { UndoIcon } from "@iconicicons/react";
import { format, minutesToHours } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import ErrorCard from "../components/ErrorCard";
import LoadingCard from "../components/LoadingCard";
import MovieCinechecks from "../components/movie/MovieCinechecks";
import MovieDescription from "../components/movie/MovieDescription";
import MovieSchedules from "../components/movie/MovieSchedules";
import MovieSchedulesList from "../components/movie/MovieSchedulesList";
import MovieTrailer from "../components/movie/MovieTrailer";
import Toolbar from "../components/Toolbar";
import { useGetMovieByIdQuery } from "../services/moviesApi";

export default function MoviePage() {
  let { movieId } = useParams();
  const navigate = useNavigate();
  const { data: movie, isError, isLoading } = useGetMovieByIdQuery(movieId!);
  const swapResponsive = "lg";

  return (
    <>
      {!isLoading && !isError && (
        <Container maxW={"full"}>
          <Toolbar title={movie!.title} buttons={(<Hide below={swapResponsive}><Button onClick={() => navigate(-1)} variant={"solid"} colorScheme={"bronze"} leftIcon={<UndoIcon/>}>Retour</Button></Hide>)} />
          <Show above={swapResponsive}>
            <Flex>
              <Box flex={5}>
                <Image
                  src={movie!.thumbnail}
                  alt={movie!.title}
                  borderRadius={"md"}
                />
              </Box>
              <VStack align={"flex-start"} flex={11} pl={8}>
                <MovieSchedules schedules={movie?.schedules ?? []} />
                <MovieDescription description={movie?.description ?? ""} />
                <MovieCinechecks
                  restriction={movie?.restriction!!}
                  cinechecks={movie?.cinechecks ?? []}
                />
                <TableContainer overflowX={"auto"}>
                  <Table variant={"unstyled"}>
                    <Tbody>
                      <Tr>
                        <Td>
                          <Text fontWeight={"bold"}>Durée</Text>
                        </Td>
                        <Td>
                          <Text>
                            {!!movie!.duration
                              ? `${minutesToHours(movie!.duration)}:${String(
                                  movie!.duration % 60
                                ).padStart(2, "0")}`
                              : "-"}
                          </Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontWeight={"bold"}>Réalisateur(s)</Text>
                        </Td>
                        <Td>
                          <Text>{movie!.director ?? "-"}</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontWeight={"bold"}>Acteur(s)</Text>
                        </Td>
                        <Td>
                          <Text>{movie!.actors ?? "-"}</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontWeight={"bold"}>Genre</Text>
                        </Td>
                        <Td>
                          <Text>{movie!.genre ?? "-"}</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontWeight={"bold"}>Origine(s)</Text>
                        </Td>
                        <Td>
                          <Text>{movie!.origin ?? "-"}</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontWeight={"bold"}>Année de production</Text>
                        </Td>
                        <Td>
                          <Text>{movie!.year ?? "-"}</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text fontWeight={"bold"}>Date de lancement</Text>
                        </Td>
                        <Td>
                          <Text>
                            {movie!.launchDate
                              ? format(
                                  new Date(movie!.launchDate),
                                  "dd/MM/yyyy"
                                )
                              : "-"}
                          </Text>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                <MovieTrailer url={movie?.trailer} />
              </VStack>
            </Flex>
          </Show>

          <Show below={swapResponsive}>
            <VStack align={"start"}>
              <Image
                src={movie!.thumbnail}
                alt={movie!.title}
                borderRadius={"md"}
                maxH={"xl"}
              />
              <MovieSchedulesList movie={movie!} />
              <MovieDescription description={movie?.description ?? ""} />
              <MovieCinechecks
                restriction={movie?.restriction!!}
                cinechecks={movie?.cinechecks ?? []}
              />
              <MovieTrailer url={movie?.trailer} width={"100%"} />
              <Stack px={2} pb={5} spacing={4} direction={"column"}>
                <Box>
                  <Heading fontSize="lg">Durée</Heading>
                  <Text mt={4} overflowWrap={"break-word"}>
                    {!!movie!.duration
                      ? `${minutesToHours(movie!.duration)}:${String(
                          movie!.duration % 60
                        ).padStart(2, "0")}`
                      : "-"}
                  </Text>
                </Box>
                <Box>
                  <Heading fontSize="lg">Réalisateur(s)</Heading>
                  <Text mt={4}>{movie!.director ?? "-"}</Text>
                </Box>
                <Box>
                  <Heading fontSize="lg">Acteur(s)</Heading>
                  <Text mt={4}>{movie!.actors ?? "-"}</Text>
                </Box>
                <Box>
                  <Heading fontSize="lg">Genre(s)</Heading>
                  <Text mt={4}>{movie!.genre ?? "-"}</Text>
                </Box>
                <Box>
                  <Heading fontSize="lg">Origine(s)</Heading>
                  <Text mt={4}>{movie!.origin ?? "-"}</Text>
                </Box>
                <Box>
                  <Heading fontSize="lg">Année de production</Heading>
                  <Text mt={4}>{movie!.year ?? "-"}</Text>
                </Box>
                <Box>
                  <Heading fontSize="lg">Date de sortie</Heading>
                  <Text mt={4}>
                    {movie!.launchDate
                      ? format(new Date(movie!.launchDate), "dd/MM/yyyy")
                      : "-"}
                  </Text>
                </Box>
              </Stack>
            </VStack>
          </Show>
        </Container>
      )}
      {isError && <ErrorCard />}
      {isLoading && <LoadingCard />}
    </>
  );
}
