import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Link,
  Popover,
  PopoverTrigger,
  useBreakpointValue,
  useDisclosure,
  Image,
  HStack,
  Heading,
  Divider,
} from "@chakra-ui/react";
import {
  CalendarIcon,
  ClockIcon,
  CloseIcon,
  FilmIcon,
  HomeIcon,
  MenuIcon,
  PhoneIcon,
} from "@iconicicons/react";
import { NavLink, useLocation } from "react-router-dom";
import LogoTamines from "../assets/logo/tamines.png";
import LogoCouvin from "../assets/logo/couvin.png";
import Networks from "./Networks";

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        bg={"rubyred.800"}
        bgBlendMode={"multiply"}
        color={"white"}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", lg: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon /> : <MenuIcon />}
            colorScheme={"rubyred"}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", lg: "start" }}>
          <HStack>
            <Image
              width={"36px"}
              src={
                process.env.REACT_APP_PLACE_ID === "cl72983jz0012jifqv6sugtef"
                  ? LogoTamines
                  : LogoCouvin
              }
            />

            <Heading
              textAlign={useBreakpointValue({ base: "center", lg: "left" })}
              fontFamily={"heading"}
              fontSize={"xl"}
              color={"white"}
            >
              {process.env.REACT_APP_NAME}
            </Heading>
          </HStack>

          <Flex display={{ base: "none", lg: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Box
          position={"relative"}
          display={{ base: "none", xl: "block" }}
        >
          <Networks />
        </Box>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav parentToggle={onToggle} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const location = useLocation();

  return (
    <HStack spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                as={NavLink}
                to={navItem.href ?? "#"}
                fontSize={"md"}
                fontWeight={500}
              >
                <Button
                  leftIcon={navItem.icon}
                  variant={
                    location.pathname.endsWith(navItem.href ?? "")
                      ? "outline"
                      : "ghost"
                  }
                  colorScheme={"gold"}
                  color={"gold.500"}
                >
                  {navItem.label}
                </Button>
              </Link>
            </PopoverTrigger>
          </Popover>
        </Box>
      ))}
    </HStack>
  );
};

type MobileNavProps = {
  parentToggle: () => void;
};

const MobileNav = ({parentToggle}: MobileNavProps) => {
  return (
    <Stack p={4} display={{ lg: "none" }} divider={<Divider/>} >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} parentToggle={parentToggle} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, href, icon, parentToggle }: NavItem & MobileNavProps) => {
  const { onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={() => {onToggle(); parentToggle()}}>
      <Flex
        py={2}
        as={NavLink}
        to={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <HStack>
          {icon}
          <Text fontWeight={600} color={"gray.200"}>
            {label}
          </Text>
        </HStack>
      </Flex>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  href?: string;
  icon: React.ReactElement;
}

export const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Accueil",
    href: "/",
    icon: <HomeIcon />,
  },
  {
    label: "Séances",
    href: "schedules",
    icon: <ClockIcon />,
  },
  {
    label: "A l'affiche",
    href: "movies",
    icon: <FilmIcon />,
  },
  {
    label: "Prochainement",
    href: "soon",
    icon: <CalendarIcon />,
  },
  {
    label: "Contact",
    href: "contact",
    icon: <PhoneIcon />,
  },
];
