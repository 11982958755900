import { HStack } from '@chakra-ui/react';
import { getCinecheckBundle } from '../../services/cinecheck';

type MovieCinechecksProps = {
    restriction: number;
    cinechecks: string[];
};

export default function MovieCinechecks({restriction, cinechecks}: MovieCinechecksProps) {
  return <HStack>{getCinecheckBundle(restriction, cinechecks, "48px")}</HStack>;
}
