import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import MoviePage from "./pages/MoviePage";
import MoviesPage from "./pages/MoviesPage";
import SchedulesPage from "./pages/SchedulesPage";
import TermsPage from "./pages/TermsPage";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={"*"} element={<Navigate to={"/"}/>}/>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="schedules" element={<SchedulesPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="terms" element={<TermsPage />} />
          <Route path="movies" element={<MoviesPage soon={false} />} />
          <Route path="soon" element={<MoviesPage soon={true} />} />
          <Route path="movie/:movieId" element={<MoviePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
