import { Box, Divider, Text, useBreakpointValue, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Schedule } from "../../services/types";
import ScheduleTags from "../ScheduleTags";

export type MovieSchedulesProps = {
    schedule: Schedule;
};

export default function MovieSchedule({ schedule }: MovieSchedulesProps) {
    const minWidth = useBreakpointValue({ base: "full", md: "fit-content" });

    return (
        <Box borderRadius="lg" p={4} minW={minWidth} key={schedule.id} backgroundColor={schedule.last ? "rubyred.600" : "smokyblack.700"}>
            <VStack align={"flex-start"} divider={<Divider />}>
                <Text>
                    {format(new Date(schedule.date), "EEE dd/MM - HH:mm", {
                        locale: fr,
                    })}
                </Text>
                <ScheduleTags schedule={schedule}/>
            </VStack>
        </Box>
    );
}
