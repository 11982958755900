import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import {Helmet} from "react-helmet";
import Router from "./Router";
import { theme } from "./theme";
import './index.css'

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{process.env.REACT_APP_NAME}</title>
          <link rel="canonical" href={process.env.REACT_APP_URL} />
          <meta name="description" content={`Site web du ${process.env.REACT_APP_NAME}. Retrouvez les horaires et séances du ${process.env.REACT_APP_NAME}. ${process.env.REACT_APP_STREET}. Programme • Séances • Cinéma`} />
          <meta property="og:title" content={`${process.env.REACT_APP_NAME}`} />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={process.env.REACT_APP_OG_IMG_URL} />
          <meta property="og:image:width" content="361" />
          <meta property="og:image:height" content="360" />
          <meta property="og:image:type" content="image/png" />
        </Helmet>
        <Router />
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
