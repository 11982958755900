import { Wrap, WrapItem, Tag, TagLeftIcon, TagLabel, useConst } from '@chakra-ui/react';
import { SpeakerIcon, TicketIcon, ProjectorIcon, AnnotationWarningIcon } from '@iconicicons/react';
import { isSameDay } from 'date-fns';
import { Schedule } from '../services/types';

export type ScheduleTagsProps = {
    schedule: Schedule
}

export default function ScheduleTags({ schedule }: ScheduleTagsProps) {
    const today = useConst(new Date());

    return (
        <Wrap>
            <WrapItem>
                <Tag size={"md"}>
                    <TagLeftIcon boxSize={"24px"} as={SpeakerIcon} />
                    <TagLabel>{schedule.version}</TagLabel>
                </Tag>
            </WrapItem>
            <WrapItem>
                <Tag size={"md"}>
                    <TagLeftIcon boxSize={"24px"} as={TicketIcon} />
                    <TagLabel>{schedule.price.toFixed(2)}€</TagLabel>
                </Tag>
            </WrapItem>

            {isSameDay(today, new Date(schedule.date)) && (
                <WrapItem>
                    <Tag size={"md"} colorScheme="gold">
                        <TagLeftIcon boxSize={"24px"} as={ProjectorIcon} />
                        <TagLabel>Aujourd'hui</TagLabel>
                    </Tag>
                </WrapItem>
            )}

            {schedule.last && (
                <WrapItem>
                    <Tag size={"md"} colorScheme="black">
                        <TagLeftIcon boxSize={"24px"} as={AnnotationWarningIcon} />
                        <TagLabel>Dernière séance</TagLabel>
                    </Tag>
                </WrapItem>
            )}

            {schedule.preview && (
                <WrapItem>
                    <Tag size={"md"} colorScheme="bronze">
                        <TagLeftIcon boxSize={"24px"} as={AnnotationWarningIcon} />
                        <TagLabel>Avant-première</TagLabel>
                    </Tag>
                </WrapItem>
            )}
        </Wrap>
    )
}
