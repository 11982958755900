import { Box, Center, HStack, Image, Link, Table, TableCaption, TableContainer, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack } from "@chakra-ui/react";
import { compareAsc, eachDayOfInterval, format, isSameDay } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Link as BaseLink } from "react-router-dom";
import { getRestrictionCinecheck } from "../services/cinecheck";
import { useGetSchedulesQuery } from "../services/schedulesApi";
import EmptyCard from "./EmptyCard";
import ErrorCard from "./ErrorCard";
import LoadingCard from "./LoadingCard";

export default function SchedulesGrid() {
    const { data, isLoading, isError } = useGetSchedulesQuery();
    const [dates, setDates] = useState([] as Date[]);

    useEffect(() => {
        if (!!data && data.length > 0) {
            const schedules = data
                .flatMap((m) => m.schedules)
                .map((s) => new Date(s.date))
                .sort((a, b) => compareAsc(a, b));

            setDates(
                eachDayOfInterval({
                    start: schedules[0],
                    end: schedules[schedules.length - 1],
                })
            );
        }
    }, [data]);

    //A function to truncate strings
    const truncate = (str: string, n: number) => {
        return str?.length > n ? str.substring(0, n - 1) + "..." : str;
    };

    return (
        <>
            {isLoading && <LoadingCard />}
            {isError && <ErrorCard />}
            {!!data && data.length === 0 && <EmptyCard />}
            {!isLoading && !isError && data!.length > 0 && (
                <TableContainer>
                    <Table variant={"striped"} colorScheme={"blackbean"}>
                        <Thead>
                            <Tr>
                                <Th color={"gray.300"}>
                                    <Text fontSize={"sm"}>Films</Text>
                                </Th>
                                <Th color={"gray.300"}>
                                    <Text fontSize={"sm"}>Âge</Text>
                                </Th>
                                {dates.map((d) => (
                                    <Th key={format(d, "dd-MM")}>
                                        <Text fontSize={"sm"} color={isSameDay(d, new Date()) ? "gold.400" : "gray.300"}>
                                            <Center>
                                                {format(d, "EEE dd/MM", {
                                                    locale: fr,
                                                })}
                                            </Center>
                                        </Text>
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {data
                                ?.filter((m) => !!m.schedules.length)
                                ?.map((m) => (
                                    <Tr key={m.id}>
                                        <Td>
                                            <HStack>
                                                <Image src={m.thumbnail} w={'32px'}/>
                                                <Tooltip label={m.title} color={"black"} isDisabled={m.title.length < 36}>
                                                    <Link fontSize={"md"} as={BaseLink} to={`/movie/${m.id}`} replace>
                                                        <Text>{truncate(m.title, 36)}</Text>
                                                    </Link>
                                                </Tooltip>
                                            </HStack>
                                        </Td>
                                        <Td>{getRestrictionCinecheck(m.restriction, "36px")}</Td>
                                        {dates.map((d) => (
                                            <Td key={`${format(d, "yyyyMMdd")}-${m.id}`}>
                                                <Center>
                                                    <VStack align={"start"}>
                                                        {m.schedules
                                                            .filter((s) => isSameDay(d, new Date(s.date)))
                                                            .map((s) => (
                                                                <Box>
                                                                    <HStack>
                                                                        <Tag variant={s.last || s.preview ? "solid" : "outline"} size={"md"} colorScheme={s.last ? "red" : s.preview ? "gold" : "gray"} color={"white"}>
                                                                            {format(new Date(s.date), "HH:mm")}
                                                                        </Tag>
                                                                        <Tag size={"sm"} variant={"solid"} colorScheme={"smokyblack"}>
                                                                            {s.version}
                                                                        </Tag>
                                                                    </HStack>
                                                                </Box>
                                                            ))}
                                                    </VStack>
                                                </Center>
                                                {!m.schedules.some((s) => isSameDay(d, new Date(s.date))) ? (
                                                    <Center>
                                                        <Text>-</Text>
                                                    </Center>
                                                ) : (
                                                    ""
                                                )}
                                            </Td>
                                        ))}
                                    </Tr>
                                ))}
                        </Tbody>
                        <TableCaption>
                            <HStack spacing={10}>
                                <HStack>
                                    <Tag variant={"outline"} colorScheme={"gray"} color={"white"}>
                                        HH:MM
                                    </Tag>
                                    <Text>Séance</Text>
                                </HStack>
                                <HStack>
                                    <Tag variant={"solid"} colorScheme={"red"} color={"white"}>
                                        HH:MM
                                    </Tag>
                                    <Text>Dernière séance</Text>
                                </HStack>
                                <HStack>
                                    <Tag variant={"solid"} colorScheme={"gold"} color={"white"}>
                                        HH:MM
                                    </Tag>
                                    <Text>Avant-Première</Text>
                                </HStack>
                                <HStack>
                                    <Tag size={"sm"} variant={"solid"} colorScheme={"smokyblack"}>
                                        VF
                                    </Tag>
                                    <Text>Version</Text>
                                </HStack>
                            </HStack>
                        </TableCaption>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}
