import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Movie } from './types'

export const schedulesApi = createApi({
    reducerPath: 'schedulesApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    endpoints: (builder) => ({
        getSchedules: builder.query<Movie[], void>({
            query: () => `schedules?place=${process.env.REACT_APP_PLACE_ID}`,
        }),
    }),
})

export const { useGetSchedulesQuery } = schedulesApi