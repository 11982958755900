import { Box, Text } from '@chakra-ui/react';
import React from 'react'

type MovieDescriptionProps = {
    description: string
  };

export default function MovieDescription({description}: MovieDescriptionProps) {
  return (
    <Box borderLeft={"white"} borderLeftWidth={"2px"} py={4}>
      <Text fontSize={"lg"}>{description}</Text>
    </Box>
  );
}
