import { Box, Heading, Link, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import Toolbar from '../components/Toolbar';

export default function Terms() {
  return (
    <>
      <Toolbar title="Termes et conditions" />
      <Box p={8}>
        <VStack
          p={8}
          spacing={4}
          align={"start"}
          bg={"smokyblack.800"}
          borderRadius={"lg"}
          shadow={"lg"}
        >
          <Text>
            As "{process.env.REACT_APP_NAME}" does not collect or determine the
            use and the purpose of data from any legal person (visitors of our
            website cinecameo.be or iOS/Android users), for all the visitors
            data "{process.env.REACT_APP_NAME}" is therefore not defined as the
            Data Controller (according to European Regulation 2016/679, “GDPR”).
            Which excludes "{process.env.REACT_APP_NAME}" from the
            responsibilities of a Data Controller. Therefore, "
            {process.env.REACT_APP_NAME}" should be considered as the Data
            Processor on behalf of its Clients as to any customer data
            containing Personal Data that is subject to the requirements of the
            GDPR.
          </Text>
          <Heading as="h2">1. Cookies</Heading>
          <Text>
            Cookies are text files placed on your computer to collect standard
            Internet log information and visitor behavior information. When you
            visit our websites, we may collect information from you
            automatically through cookies or similar technology (for further
            information, visit{" "}
            <Link isExternal href="http://allaboutcookies.org">
              http://allaboutcookies.org
            </Link>
            .)
          </Text>
          <Text>
            You can set your browser not to accept cookies, and the above
            website tells you how to remove cookies from your browser. However,
            in a few cases, some of our website features may not function as a
            result.
          </Text>
          <Heading as="h2">2. Type of Cookies</Heading>
          <Text>
            <b>Essential cookies</b> enable core functionality such as page
            navigation and access to secure areas. The website cannot function
            properly without these cookies.
          </Text>
        </VStack>
      </Box>
    </>
  );
}
