import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { CloseIcon } from '@iconicicons/react'
import React from 'react'

export default function ErrorCard() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={"red.500"}
          rounded={"50px"}
          w={"55px"}
          h={"55px"}
          textAlign="center"
        >
          <CloseIcon width={"24px"} color={"white"} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Une erreur s'est produite
      </Heading>
      <Text color={"gray.500"}>Veuillez réessayer plus tard.</Text>
    </Box>
  );
}
