import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { movieApi } from '../services/moviesApi';
import { placeApi } from '../services/placeApi';
import { postApi } from '../services/postApi';
import { schedulesApi } from '../services/schedulesApi';

export const store = configureStore({
  reducer: {
    [movieApi.reducerPath]: movieApi.reducer,
    [schedulesApi.reducerPath]: schedulesApi.reducer,
    [placeApi.reducerPath]: placeApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(movieApi.middleware, schedulesApi.middleware, placeApi.middleware, postApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
