import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { Post } from './types'

export const postApi = createApi({
    reducerPath: 'postApi',
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    endpoints: (builder) => ({
        getPost: builder.query<Post, void>({
            query: () => `post?placeId=${process.env.REACT_APP_PLACE_ID}`,
        }),
    }),
})

export const { useGetPostQuery } = postApi