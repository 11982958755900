import { HStack, IconButton, Link } from "@chakra-ui/react";
import { FaFacebookSquare, FaInstagram, FaTwitter, FaTiktok } from "react-icons/fa";
import { useGetPlaceQuery } from "../services/placeApi";

export default function Networks() {
  const { data: place, isLoading, isError } = useGetPlaceQuery();

  return (
    <>
      {!isLoading && !isError && place && (
        <HStack spacing={5} px={5} alignItems="center">
          {place.networks
            ?.map((network) => network.split("|"))
            .map((network, index) => (
              <Link key={`network-${index}`} href={network[1]} isExternal>
                <IconButton
                  aria-label={network[0]}
                  variant="ghost"
                  size="lg"
                  isRound={true}
                  _hover={
                    network[0] === "facebook"
                      ? { bg: "#0D74FF" }
                      : network[0] === "instagram"
                      ? { bg: "#bc2a8d" }
                      : network[0] === "twitter"
                      ? { bg: "#1DA1F2" }
                      : network[0] === "tiktok"
                      ? { bg: "#000000" }
                      : {}
                  }
                  icon={
                    network[0] === "facebook" ? (
                      <FaFacebookSquare size={"24px"} />
                    ) : network[0] === "instagram" ? (
                      <FaInstagram size={"24px"} />
                    ) : network[0] === "twitter" ? (
                      <FaTwitter size={"24px"} />
                    ) : network[0] === "tiktok" ? (
                      <FaTiktok size={"24px"} />
                    ) : (
                      <></>
                    )
                  }
                />
              </Link>
            ))}
        </HStack>
      )}
    </>
  );
}
