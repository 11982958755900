import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Link as HtmlLink,
  Center,
  Show,
  Progress,
  CircularProgress,
} from "@chakra-ui/react";
import { ClockIcon, FilmIcon } from "@iconicicons/react";
import { Link } from "react-router-dom";
import GooglePlayBadge from "../assets/img/google-play-badge.png";
import AppStoreBadge from "../assets/img/app-store-badge.png";
import Networks from "../components/Networks";
import ReactMarkdown from "react-markdown";
import { theme } from "../theme";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { useGetPostQuery } from "../services/postApi";
import { useGetPlaceQuery } from "../services/placeApi";

export default function HomePage() {
  const { data: post, isLoading: isPostLoading } = useGetPostQuery();
  const { data: place, isLoading: isPlaceLoading } = useGetPlaceQuery();

  return (
    <Container maxW={"7xl"}>
      <Stack
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        pt={{ base: 20, md: 28 }}
        direction={{ base: "column", md: "row" }}
      >
        <Stack flex={1} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
          >
            <Text as={"span"} position={"relative"}>
              {process.env.REACT_APP_HERO_NAME}
            </Text>
            <br />
            <Text as={"span"} color={"rubyred.400"}>
              {process.env.REACT_APP_HERO_TOWN}
            </Text>
          </Heading>
          {isPostLoading && (
            <Progress size="xs" color="rubyred.300" isIndeterminate />
          )}
          {!isPostLoading && !!post && (
            <ReactMarkdown
              components={ChakraUIRenderer(theme)}
              skipHtml
              children={post.text}
            />
          )}
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <Button
              as={Link}
              to={"/schedules"}
              rounded={"full"}
              size={"lg"}
              fontWeight={"normal"}
              px={6}
              colorScheme={"gold"}
              bg={"gold.400"}
              _hover={{ bg: "gold.500" }}
              leftIcon={<ClockIcon />}
            >
              Séances
            </Button>
            <Button
              as={Link}
              to={"/movies"}
              rounded={"full"}
              size={"lg"}
              fontWeight={"normal"}
              px={6}
              leftIcon={<FilmIcon />}
            >
              A l'affiche
            </Button>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={"center"}
          align={"center"}
          position={"relative"}
          w={"full"}
        >
          {!isPlaceLoading && !!place && !post && (
            <Box
              position={"relative"}
              height={"350px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"hidden"}
            >
              {isPlaceLoading && (
                <Center>
                  <CircularProgress isIndeterminate color="rubyred.300" />
                </Center>
              )}
              <Image
                alt={"Hero Image"}
                fit={"cover"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                boxShadow={"dark-lg"}
                src={place.image ?? process.env.REACT_APP_HERO_IMG_URL}
              />
            </Box>
          )}
          {!!post && !isPostLoading && (
            <Box
              position={"relative"}
              height={"fit-content"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              overflow={"hidden"}
            >
              <Image
                alt={"Post image"}
                fit={"contain"}
                align={"center"}
                w={"100%"}
                h={"100%"}
                boxShadow={"dark-lg"}
                src={post.thumbnail}
              />
            </Box>
          )}
        </Flex>
      </Stack>
      <Center>
        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 18, md: 20 }}
          direction={{ base: "column", md: "row" }}
        >
          <HtmlLink href={process.env.REACT_APP_GOOGLE_PLAY_URL} isExternal>
            <Image
              alt="Disponible sur Google Play"
              maxW={"240px"}
              src={GooglePlayBadge}
            />
          </HtmlLink>
          <HtmlLink href={process.env.REACT_APP_APP_STORE_URL} isExternal>
            <Image
              alt="Disponible sur Google Play"
              maxW={"240px"}
              src={AppStoreBadge}
            />
          </HtmlLink>
        </Stack>
      </Center>
      <Center>
        <Show below="md">
          <Box mt={8}>
            <Networks />
          </Box>
        </Show>
      </Center>
    </Container>
  );
}
