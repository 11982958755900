import {
  Box,
  Container,
  DarkMode,
  Hide,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { Link as BaseLink, Outlet } from "react-router-dom";
import Navbar, { NAV_ITEMS } from "./components/Navbar";

export default function Layout() {
  return (
      <div className="App">
          <DarkMode>
              <Navbar />

              <Container maxW={"full"} minH={"86vh"}>
                  <Outlet />
              </Container>
              <Box>
                  <Container as={Stack} maxW={"8xl"} py={4} direction={{ base: "column", md: "row" }} spacing={4} justify={{ base: "center", md: "space-between" }} align={{ base: "center", md: "center" }}>
                      <Hide below="lg">
                          <Stack direction={"row"} spacing={4}>
                              {NAV_ITEMS.map((n, index) => (
                                  <Link key={`navigation-${index}`} fontSize={"sm"} as={BaseLink} to={n.href ?? "#"}>
                                      {n.label}
                                  </Link>
                              ))}
                              <Link as={BaseLink} to={"/terms"} fontSize={"sm"}>
                                  Termes et conditions
                              </Link>
                          </Stack>
                      </Hide>

                      <Text fontSize={"sm"}>© {format(Date.now(), "yyyy")} Dreamline SPRL. All rights reserved</Text>
                      <Text fontSize={"sm"}>
                          Made by{" "}
                          <Link href={"https://vandeas.be/"} isExternal>
                              Clément Vandendaelen
                          </Link>
                      </Text>
                  </Container>
              </Box>
          </DarkMode>
      </div>
  );
}
