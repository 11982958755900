import { Container, Hide, Show } from "@chakra-ui/react";
import SchedulesGrid from "../components/SchedulesGrid";
import SchedulesList from "../components/SchedulesList";
import Toolbar from "../components/Toolbar";

export default function SchedulesPage() {
    const breakPoint = "md";

    return (
        <Container maxW={"full"}>
            <Toolbar title="Séances" />
            <Show below={breakPoint}>
                <SchedulesList />
            </Show>
            <Hide below={breakPoint}>
                <SchedulesGrid />
            </Hide>
        </Container>
    );
}
